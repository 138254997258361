@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');

body {
	margin: 0;
	font-size: 16px;
	background: #47c6ee;
}
.content {
/* 	background: linear-gradient(to bottom, rgba(135,224,253,1) 0%,rgba(83,203,241,1) 40%,rgba(5,171,224,1) 100%); */
	padding: 2em 1em;
	height: 100%;
	width: 100vw;
	box-sizing: border-box;
	text-align: center;
	color: white;
	font-family: 'Lato', sans-serif;
	overflow: hidden;
	position: relative;
}
.content p,
.content h1,
.content img {
	position: relative;
	z-index: 1;
}
.address {
	margin: 2em 0;
}
.address a {
	font-weight: bold;
	font-size: 1.5em;
	color: white;
}
.thanks {
	font-size: 1.2em;
}
.gradient1 {
	background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(255,255,0,0.09) 14%,rgba(255,255,0,0.65) 100%);
	height: 140px;
	transform: skewY(20deg);
	position: absolute;
	top: 30px;
	width: 100%;
	left: 0;
	z-index: 0;
}
.gradient2 {
	background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,191,255,0.09) 14%,rgba(0,191,255,0.65) 100%);
	height: 180px;
	transform: skewY(-42deg);
	position: absolute;
	top: 300px;
	width: 100%;
	left: 0;
	z-index: 0;
}
.gradient3 {
	background: linear-gradient(to right, rgba(255,0,255,0.65) 0%,rgba(255,0,255,0.09) 86%,rgba(0,0,0,0) 100%);
	height: 90px;
	transform: skewY(8deg);
	position: absolute;
	top: 570px;
	width: 100%;
	left: 0;
	z-index: 0;
}
